const burgerBtn = document.querySelector('.nav__hamburger-btn')
const nav = document.querySelector('.nav')
const navMobile = document.querySelector('.nav__mobile-links')
const mobileNavLinks = document.querySelectorAll('.nav__mobile-link')
const headerImgOne = document.querySelector('.header__img--one')
const headerImgTwo = document.querySelector('.header__img--two')
const headerImgThree = document.querySelector('.header__img--three')
const year = document.querySelector('.footer__year')
const msgStatus = document.querySelector('.contact__msg-status')
const contactForm = document.querySelector('.contact__form')

const swapHeaderImg = () => {
	setTimeout(() => {
		headerImgOne.style.opacity = '0'
		headerImgTwo.style.opacity = '1'
	}, 2000)
	setTimeout(() => {
		headerImgTwo.style.opacity = '0'
		headerImgThree.style.opacity = '1'
	}, 4000)
	setTimeout(() => {
		headerImgThree.style.opacity = '0'
		headerImgOne.style.opacity = '1'
	}, 6000)
}

swapHeaderImg()
setInterval(swapHeaderImg, 6000)

const handleMenu = () => {
	let delay = 100

	if (navMobile.classList.contains('active') == false) {
		navMobile.style.right = '0px'
		nav.classList.add('nav__scrolled')
		mobileNavLinks.forEach(el => {
			setTimeout(() => {
				el.style.right = '0'
			}, delay)
			delay += 100
		})
	} else {
		mobileNavLinks.forEach(el => {
			setTimeout(() => {
				el.style.right = '-110%'
			}, delay)
			delay += 100
		})
		setTimeout(() => (navMobile.style.right = '-110%'), 400)
	}
	navMobile.classList.toggle('active')
}

const menuTransparency = () => {
	if (window.scrollY > 20) {
		nav.classList.add('nav__scrolled')
	} else {
		if (navMobile.classList.contains('active') == false) {
			nav.classList.remove('nav__scrolled')
		}
	}
}

window.addEventListener('scroll', menuTransparency)

const setYear = () => {
	let currentYear = new Date().getFullYear()
	year.textContent = currentYear
}

setYear()

burgerBtn.addEventListener('click', handleMenu)
mobileNavLinks.forEach(el =>
	el.addEventListener('click', () => {
		navMobile.style.right = '-110%'
		navMobile.classList.toggle('active')
	})
)

contactForm.addEventListener('submit', function (event) {
	let name = document.getElementById('name').value
	let email = document.getElementById('email').value
	let msg = document.getElementById('msg').value
	const nameErrorMsg = document.querySelector('.contact__name-error')
	const emailErrorMsg = document.querySelector('.contact__email-error')
	const msgErrorMsg = document.querySelector('.contact__msg-error')

	nameErrorMsg.textContent = ''
	emailErrorMsg.textContent = ''
	msgErrorMsg.textContent = ''

	if (name == '') {
		nameErrorMsg.textContent = 'Wypełnij pole'
		event.preventDefault()
	}
	if (email == '' || !email.includes('@') || !email.includes('.')) {
		emailErrorMsg.textContent = 'Podaj prawidłowy adres e-mail'
		event.preventDefault()
	}
	if (msg == '') {
		msgErrorMsg.textContent = 'Wpisz treść wiadomości'
		event.preventDefault()
	}
})

contactForm.addEventListener('keydown', function (event) {
	if (event.key == 'Enter') {
		event.preventDefault()
	}
})

document.addEventListener('DOMContentLoaded', event => {
	if (document.location.search == '?mail_status=sent') {
		msgStatus.classList.add('success')

		setTimeout(() => {
			msgStatus.classList.remove('success')
		}, 3000)
	}
})
